<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="product.Descricao"
                        :subtitle="product.Artigo"
                        :back="true"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile color="primary" class="mr-4" @click="syncProduct()" v-if="writePermission('products/product')">
                    <i class="fas fa-sync mr-1"></i>Sincronizar
                </v-btn>
                <v-btn tile color="error" :href="'https://b2b.bicimax.com/product?id='+productId" target="_blank">B2B
                    <i class="fas fa-external-link-alt ml-1"></i>
                </v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row v-if="Object.keys(product).length > 0">
            <v-col>
                <v-tabs background-color="transparent" slider-color="rgba(192, 13, 13, 1)" color="rgba(192, 13, 13, 1)">
                    <v-tab @click="activeTab = 1" :style="[activeTab === 1 ? {'background-color': 'white'} : '']">
                        Definições básicas
                    </v-tab>
                    <v-tab @click="activeTab = 2" :style="[activeTab === 2 ? {'background-color': 'white'} : '']">
                        Descrições
                    </v-tab>
                    <v-tab @click="activeTab = 3" :style="[activeTab === 3 ? {'background-color': 'white'} : '']">
                        Stocks
                    </v-tab>
                    <v-tab v-if="product.TratamentoDim !== 0" @click="activeTab = 4" :style="[activeTab === 4 ? {'background-color': 'white'} : '']">
                        Dimensões
                    </v-tab>
                    <v-tab v-else @click="activeTab = 5" :style="[activeTab === 5 ? {'background-color': 'white'} : '']">
                        Caixas
                    </v-tab>
                  <v-tab @click="activeTab = 6" :style="[activeTab === 6 ? {'background-color': 'white'} : '']">
                    Códigos de Barra Caixa
                  </v-tab>
                  <v-tab v-if="$readPermission('products/productPurchases')" @click="activeTab = 7" :style="[activeTab === 7 ? {'background-color': 'white'} : '']">
                  Compras
                  </v-tab>
                    <v-tab v-if="$readPermission('products/products_extra')" @click="activeTab = 8" :style="[activeTab === 8 ? {'background-color': 'white'} : '']">
                        Produtos Extra
                    </v-tab>
                </v-tabs>
                <BasicSettings v-if="activeTab === 1" :productId="productId"></BasicSettings>
                <Description v-if="activeTab === 2" :productId="productId"></Description>
                <Stock v-if="activeTab === 3" :productId="productId"></Stock>
                <Dimension v-if="activeTab === 4" :productId="productId"></Dimension>
                <ProductBoxQuantityForm v-if="activeTab === 5" :products="[productId]" :singleProductMode="true" :fullScreen="true"/>
                <BoxBarcode v-if="activeTab === 6" :productId="productId"/>
                <ProductPurchases v-if="activeTab === 7" :productId="productId"/>
                <ProductExtras v-if="activeTab === 8" :productId="productId" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'
    import BasicSettings from "./Product/BasicSettings";
    import Stock from "./Product/Stock";
    import Description from "./Product/Description";
    import Dimension from "./Product/Dimension";
    import ProductBoxQuantityForm from '@/components/Products/AddBoxQuantity.vue';
    import BoxBarcode from "@/components/Products/Product/BoxBarcode";
    import ProductPurchases from "@/components/Products/Product/ProductPurchases.vue";
    import ProductExtras from "@/components/Products/Product/ProductExtras.vue";

    export default {
        components: {ProductPurchases, BoxBarcode, Dimension, Description, Stock, BasicSettings, ProductBoxQuantityForm, ProductExtras},
        data: function () {
            return {
                productId: 0,
                product: {},
                activeTab: 1
            }
        },
        created() {
            this.productId = this.$route.params.id;
            document.title = i18n.t('product') + ' ' + this.productId + ' | STAFF';
            this.getProduct()
        },
        methods: {
            getProduct() {
                let request = {
                    url: api.products() + '/' + this.productId + '/name'
                };

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.product = responseData.product
                        } else {
                            Alert.dispatch('show', {snackbar: true, text: 'Erro de servidor!', type: 'error'})
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {snackbar: true, text: 'Erro de servidor!', type: 'error'})
                    })
            },
            syncProduct() {
                let request = {
                    url: api.products() + '/' + this.productId + '/sync'
                };

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {snackbar: true, text: 'Produto a sincronizar!', type: 'success'})
                        } else {
                            Alert.dispatch('show', {snackbar: true, text: 'Erro de servidor!', type: 'error'})
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {snackbar: true, text: 'Erro de servidor!', type: 'error'})
                    })
            },
            writePermission(permission) {
                return User.getters.writePermission(permission)
            }
        }
    }
</script>
