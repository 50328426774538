<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        :title="$t('sales')+' Cliente/Marca'"
                        :subtitle="'A mostrar '+salesClient.length+' de '+totalSalesClient"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-row>
                    <v-col>
                        <v-btn small @click="download" color="success">Download</v-btn>
                    </v-col>
                    <v-col cols="2">
                        <v-select
                                v-model="pagination.per_page"
                                :items="filtersPerPage"
                                outlined
                                single-line
                                @change="changeSelect"
                                item-text="TEXT"
                                item-value="VALUE"
                                style="width: 90px; margin-left: auto;"
                                class="staff_select mb-0"
                                hide-details
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="2">
                <!-- <v-select
                        v-model="filters.year"
                        label="Ano"
                        :items="years"
                        @change="changeSelect"
                        hide-details
                        class="staff_select"
                ></v-select> -->
                <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="filters.startDate"
                        label="Data Inicial"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="filters.startDate"
                    @change="changeSelect"
                    @input="menuStartDate = false"
                    locale="es-es"
                    ></v-date-picker>
                </v-menu>
       <!-- {{ filters }} -->
            </v-col>
            <v-col cols="12" sm="6" md="2">   
                <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="filters.endDate"
                        label="Data Final"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="filters.endDate"
                    @change="changeSelect"
                    @input="menuEndDate = false"
                    locale="es-es"
                    ></v-date-picker>
                </v-menu>             
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="$writePermission('reports/filters') && salesman.length > 0">
                <v-select
                        v-model="filters.salesman_id"
                        label="Vendedor"
                        :items="salesman"
                        item-value="Vendedor"
                        item-text="Nome"
                        @change="changeSelect"
                        hide-details
                        class="staff_select"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="white-space: nowrap" @click="setOrder('Cliente', filters.orderAsc)">Cliente
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'Cliente'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'Cliente'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th style="white-space: nowrap" @click="setOrder('Nome', filters.orderAsc)">{{ $t('name') }}
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'Nome'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'Nome'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('BICIMAX', filters.orderAsc)">BICIMAX
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'BICIMAX'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'BICIMAX'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('CLASSIFIED', filters.orderAsc)">CLASSIFIED
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'CLASSIFIED'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'CLASSIFIED'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('EASTON', filters.orderAsc)">EASTON
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'EASTON'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'EASTON'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('FOX', filters.orderAsc)">FOX
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'FOX'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'FOX'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('FOXSERVICE', filters.orderAsc)">FOXSERVICE
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'FOXSERVICE'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'FOXSERVICE'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('KENDA', filters.orderAsc)">KENDA
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'KENDA'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'KENDA'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-center" style="white-space: nowrap"
                                @click="setOrder('KMC', filters.orderAsc)">KMC
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'KMC'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'KMC'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('MARZOCCHI', filters.orderAsc)">MARZOCCHI
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'MARZOCCHI'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'MARZOCCHI'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('NAMEDSPORT', filters.orderAsc)">NAMEDSPORT
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'NAMEDSPORT'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'NAMEDSPORT'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('EVOC', filters.orderAsc)">EVOC
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'EVOC'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'EVOC'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('OUTLET', filters.orderAsc)">OUTLET
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'OUTLET'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'OUTLET'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('RACEFACE', filters.orderAsc)">RACEFACE
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'RACEFACE'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'RACEFACE'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('REPUESTOS', filters.orderAsc)">REPUESTOS
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'REPUESTOS'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'REPUESTOS'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('RESERVE', filters.orderAsc)">RESERVE
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'RESERVE'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'RESERVE'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('RIDEMAX', filters.orderAsc)">RIDEMAX
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'RIDEMAX'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'RIDEMAX'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('RIDECONCEP', filters.orderAsc)">RIDECONCEPT
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'RIDECONCEP'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'RIDECONCEP'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('SANTACRUZ', filters.orderAsc)">SANTACRUZ
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'SANTACRUZ'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'SANTACRUZ'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('SMANIE', filters.orderAsc)">SMANIE
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'SMANIE'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'SMANIE'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('TAYA', filters.orderAsc)">TAYA
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'TAYA'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'TAYA'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('TECMOUSSE', filters.orderAsc)">TECMOUSSE
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'TECMOUSSE'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'TECMOUSSE'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('UDOG', filters.orderAsc)">UDOG
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'UDOG'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'UDOG'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('CERVELO', filters.orderAsc)">CERVELO
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'CERVELO'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'CERVELO'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('CEMA', filters.orderAsc)">CEMA
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'CEMA'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'CEMA'"
                                   class="fas fa-angle-up"></i>
                            </th>
                            <th class="text-right" style="white-space: nowrap"
                                @click="setOrder('CURRENT_YEAR', filters.orderAsc)">TOTAL
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'CURRENT_YEAR'"
                                   class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'CURRENT_YEAR'"
                                   class="fas fa-angle-up"></i>
                            </th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                        v-model="filters.client"
                                        hide-details
                                        outlined
                                        single-line
                                        @keypress.enter="changeSelect"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        hide-details
                                        outlined
                                        single-line
                                        @keypress.enter="changeSelect"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="25">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="sale in salesClient">
                            <td>{{ sale.Cliente }}</td>
                            <td>{{ sale.Nome }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.BICIMAX) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.CLASSIFIED) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.EASTON) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.FOX) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.FOXSERVICE) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.KENDA) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.KMC) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.MARZOCCHI) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.NAMEDSPORT) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.EVOC) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.OUTLET) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.RACEFACE) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.REPUESTOS) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.RESERVE) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.RIDEMAX) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.RIDECONCEP) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.SANTACRUZ) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.SMANIE) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.TAYA) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.TECMOUSSE) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.UDOG) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.CERVELO) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.CEMA) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.CURRENT_YEAR) }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="initialize"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'

export default {
    data: () => ({
        loading: false,
        salesClient: [],
        filters: {
            year: new Date().getFullYear(),
            startDate: new Date().getFullYear()+'-01-01',
            endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            salesman_id: 'ALL',
            client: '',
            name: '',
            fiscalName: '',
            orderColumn: 'Cliente',
            orderAsc: true
        },
        years: [
            new Date().getFullYear(),
            new Date().getFullYear() - 1,
            new Date().getFullYear() - 2,
            new Date().getFullYear() - 3
        ],
        salesman: [],
        pagination: {
            page: 1,
            per_page: 250
        },
        totalSalesClient: 0,
        filtersPerPage: [
            {TEXT: '100', VALUE: 100},
            {TEXT: '250', VALUE: 250},
            {TEXT: '500', VALUE: 500},
            {TEXT: '750', VALUE: 750}
        ],
        menuStartDate: false,
        menuEndDate: false,
    }),
    created() {
        document.title = i18n.t('sales') + ' Cliente/Marca'
        this.initialize()
    },
    methods: {
        initialize() {
            this.loading = true
            this.salesClient = []

            if (!this.writePermission('reports/sales/client/brand')) {
                this.filters.salesman_id = User.getters.getSalesmanId
            }

            let request = {
                url: api.reportsSalesClientBrand(),
                data: {
                    filters: this.filters,
                    pagination: this.pagination
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.salesman = responseData.salesman
                            this.salesClient = responseData.salesClient
                            this.totalSalesClient = responseData.totalSalesClient
                            this.loading = false
                        } else {
                            this.loading = false
                        }
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        changeSelect() {
            this.pagination.page = 1
            this.initialize()
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        setOrder(column, order) {
            this.filters.orderAsc = this.filters.orderColumn !== column ? order : !order
            this.filters.orderColumn = column
            this.initialize()
        },
        download() {
            this.filters.pagination = this.pagination
            Reports.dispatch('getSalesByClientBrandDownload', this.filters)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/csv'
                        });
                        console.log(response)
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'Report_Client_Brand_' + this.filters.salesman_id + '_' + '.csv';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    })
                    .catch(response => {

                    })
        }
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.totalSalesClient / this.pagination.per_page)
        }
    }
}
</script>
