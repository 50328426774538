<style scoped>
.staff_table .v-input__control .v-input__slot {
    height: 28px !important;
    min-height: 22px !important;
    font-size: 12px !important;
    padding: 0 6px;
}

.total_table th,
.total_table td {
    height: 27px !important;
    font-size: 12px !important;
    padding: 0 4px !important;
}

.v-tabs-bar .v-tabs-bar {
    height: 32px !important;
}

.staff_table.docs th, .staff_table.docs td {
    height: 22px !important;
}
</style>

<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="5">
                <page-title
                        v-if="pickOrder.cliente !== 'VD' && pickOrder.cliente !== 'VDES' && pickOrder.cliente !== 'VDSHOP' && pickOrder.cliente !== 'VDSHOPES'"
                        :title="'Faturação Picking '+pickOrderId"
                        :subtitle="pickOrder.cliente+' - '+pickOrder.nome"
                ></page-title>
                <page-title
                        v-if="pickOrder.cliente === 'VD' || pickOrder.cliente === 'VDES' || pickOrder.cliente === 'VDSHOP' || pickOrder.cliente === 'VDSHOPES'"
                        :title="'Faturação Picking '+pickOrderId"
                        :subtitle="pickOrder.cliente+' - '+pickOrder.Nome"
                ></page-title>
            </v-col>
            <v-col class="text-right" align-self="center">
                <v-btn tile small color="warning" class="mr-2" @click="toggleDialogDeporvillage()">Deporvillage</v-btn>
                <v-btn tile small color="success" class="mr-2" @click="printInvoice()">Re-Imprimir FATURA</v-btn>
                <v-btn tile small color="primary" class="mr-2" :to="'/orders/pick/' + this.pickOrderId"
                       v-if="pickOrder.numDocFat === null">
                    Picking
                </v-btn>
                <v-btn tile small color="error" v-if="pickOrder.numDocFat !== null" @click="closeInvoice">Fechar e
                    Arquivar
                </v-btn>
                <v-btn tile small color="error" v-if="pickOrder.numDocFat === null" @click="closeInvoice">Eliminar e
                    Fechar
                </v-btn>
            </v-col>
        </v-row>

        <!-- MAIN TOP -->
        <v-row>

            <v-col cols="3">
                <v-card min-height="274px">
                    <v-card-text>
                        <v-simple-table class="staff_table docs">
                            <tbody>
                            <tr>
                                <th>Data</th>
                                <th>Documento</th>
                                <th class="text-center">ModoPag</th>
                            </tr>
                            <tr v-for="doc in docs">
                                <td>{{ doc.Data.substring(0, 10) }}</td>
                                <td>{{ doc.TipoDoc }} {{ doc.Serie }} / {{ doc.NumDoc }}</td>
                                <td class="text-center">{{ doc.ModoPag }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="7">
                <v-card min-height="274px">
                    <v-card-text>
                        <v-row>

                            <v-col cols="6" class="py-0">
                                <v-simple-table class="staff_table">
                                    <tbody>
                                    <tr>
                                        <td class="black--text font-weight-bold" style="width: 1px">
                                            <v-btn small tile @click="toggleDialogClientInfo()">{{
                                                    client.Cliente
                                                }}
                                            </v-btn>
                                        </td>
                                        <td class="black--text font-weight-bold"><strong>{{
                                                client.NomeFiscal
                                            }}</strong></td>
                                    </tr>
                                    <tr>
                                        <th>Contribuinte</th>
                                        <td class="text-right">
                                            <span v-if="validVat=='OK'">&#10004;&#65039;</span>
                                            <span v-if="validVat=='WRN'">&#10060;</span>
                                            <span v-if="validVat=='NOK'" style="font-size:medium">&#9888;&#65039;</span>
                                            <v-btn small tile @click="toggleDialogValidateVies()">
                                                {{ client.NumContrib }}
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Crédito</th>
                                        <td class="text-right">
                                            <v-chip small v-if="client.TipoCred === '1'">Por limite</v-chip>
                                            <v-chip small color="error" v-else>Suspenso</v-chip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Disponível</th>
                                        <td class="text-right">{{ $money(client.LimiteCred - client.TotalDeb) }}</td>
                                    </tr>
                                    <tr>
                                        <th style="vertical-align: middle">
                                            <!--                                            <div style="margin-top: 6px;">Morada</div>-->
                                            <v-btn v-if="tabAddress!==0" small block text class="mt-2"
                                                   @click="tabAddress=1"
                                                   :class="tabAddress===1 ? 'primary' : ''">Envio
                                            </v-btn>
                                            <v-btn v-if="tabAddress!==0" small block text class=""
                                                   @click="tabAddress=2"
                                                   :class="tabAddress===2 ? 'primary' : ''">Faturação
                                            </v-btn>
                                        </th>
                                        <td>
                                            <table style="width: 100%">
                                                <tr>
                                                    <!--                                                    <td style="vertical-align: top; width: 1px;">-->
                                                    <!--                                                        <v-btn v-if="tabAddress!==0" small block text class="mt-2"-->
                                                    <!--                                                               @click="tabAddress=1"-->
                                                    <!--                                                               :class="tabAddress===1 ? 'primary' : ''">Envio-->
                                                    <!--                                                        </v-btn>-->
                                                    <!--                                                        <v-btn v-if="tabAddress!==0" small block text class=""-->
                                                    <!--                                                               @click="tabAddress=2"-->
                                                    <!--                                                               :class="tabAddress===2 ? 'primary' : ''">Faturação-->
                                                    <!--                                                        </v-btn>-->
                                                    <!--                                                    </td>-->
                                                    <td>
                                                        <div class="pa-2" v-if="tabAddress===1">
                                                            <p class="mb-0">
                                                                <v-row>
                                                                    <v-col class="py-0 pr-0" cols="3"
                                                                           v-if="pickOrder.cliente !== 'VDSHOPES' && pickOrder.cliente !== 'VDES' && pickOrder.cliente !== 'VD' && pickOrder.cliente !== 'VDSHOP' && addresses.length > 1">
                                                                        <template>
                                                                            <v-select
                                                                                    :items="addresses"
                                                                                    outlined
                                                                                    single-line
                                                                                    v-model="shippingAddress.Id"
                                                                                    item-value="ID"
                                                                                    dense
                                                                                    class="staff_select_small"
                                                                                    hide-details
                                                                                    @change="updateShippingAddress()"
                                                                            >
                                                                                <template v-slot:item="{ item }">
                                                                                    {{ item.ID }} - {{ item.Address }}
                                                                                    {{ item.Address2 }}
                                                                                    {{ item.Locality }} {{ item.Cp }} {{
                                                                                        item.CpLocality
                                                                                    }} {{ item.Country }}
                                                                                </template>
                                                                                <template v-slot:selection="{ item }">
                                                                                    {{ item.ID }}
                                                                                </template>
                                                                            </v-select>
                                                                        </template>
                                                                    </v-col>
                                                                    <v-col class="py-2 pr-0">
                                                                        <template
                                                                                v-if="pickOrder.cliente === 'VDSHOPES' || pickOrder.cliente === 'VDES' || pickOrder.cliente === 'VD' || pickOrder.cliente === 'VDSHOP' || addresses.length === 1">
                                                                            {{ shippingAddress.Id }}
                                                                        </template>
                                                                        - {{ shippingAddress.Fac_Mor_EN }}
                                                                    </v-col>
                                                                </v-row>
                                                            </p>
                                                            <p class="mb-0">{{ shippingAddress.Fac_Mor2_EN }}</p>
                                                            <p class="mb-0">{{ shippingAddress.Fac_Local_EN }}</p>
                                                            <p class="mb-0">{{ shippingAddress.Fac_Cp_EN }} - {{
                                                                    shippingAddress.Fac_CpLocal_EN
                                                                }}</p>
                                                            <p class="mb-0">{{ shippingAddress.Fac_Zona_EN }} |
                                                                {{ shippingAddress.Pais_EN }}</p>
                                                        </div>
                                                        <div class="pa-2" v-if="tabAddress===2">
                                                            <p class="mb-0">{{ pickOrder.Fac_Mor_FT }}</p>
                                                            <p class="mb-0">{{ pickOrder.Fac_Mor2_FT }}</p>
                                                            <p class="mb-0">{{ pickOrder.Fac_Local_FT }}</p>
                                                            <p class="mb-0">{{ pickOrder.Fac_Cp_FT }} - {{
                                                                    pickOrder.Fac_CpLocal_FT
                                                                }}</p>
                                                            <p class="mb-0">{{ pickOrder.Fac_Zona_FT }} |
                                                                {{ pickOrder.Pais_FT }}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>

                            <v-col cols="6" class="py-0">
                                <v-simple-table v-if="pickOrder.numDocFat === null" class="staff_table">
                                    <tbody>
                                    <tr>
                                        <td style="width:120px"><strong>Tipo Documento</strong></td>
                                        <td>
                                            <v-btn tile small @click="inputConditions.typeDoc='FAPO'"
                                                   :class="{'primary': inputConditions.typeDoc === 'FAPO'}">FAPO
                                            </v-btn>
                                            <v-btn tile small @click="inputConditions.typeDoc='FA'"
                                                   :class="{'primary': inputConditions.typeDoc === 'FA'}">FA
                                            </v-btn>
                                            <v-btn tile small @click="inputConditions.typeDoc='FAP'"
                                                   :class="{'primary': inputConditions.typeDoc === 'FAP'}">FAP
                                            </v-btn>
                                            <v-btn tile small @click="inputConditions.typeDoc='FP'"
                                                   :class="{'warning': inputConditions.typeDoc === 'FP'}">FP
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Modo Pagamento</strong></td>
                                        <td>
                                            <v-btn tile small @click="inputConditions.modePag='RTB'"
                                                   :class="{'primary': inputConditions.modePag === 'RTB'}">RTB
                                            </v-btn>
                                            <v-btn tile small @click="inputConditions.modePag='DEBDI'"
                                                   :class="{'primary': inputConditions.modePag === 'DEBDI'}">DEBDI
                                            </v-btn>
                                            <v-btn tile small @click="inputConditions.modePag='CTB'"
                                                   :class="{'primary': inputConditions.modePag === 'CTB'}">CTB
                                            </v-btn>
                                            <v-btn tile small @click="inputConditions.modePag='CNTRE'"
                                                   :class="{'primary': inputConditions.modePag === 'CNTRE'}">CNTRE
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Cond. Pagamento</strong></td>
                                        <td>
                                            <v-select
                                                    class="staff_select_small"
                                                    v-model="inputConditions.condPag"
                                                    :items="paymentConditions"
                                                    item-text="Descricao"
                                                    item-value="CondPag"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    @input="changeCondPag"
                                            ></v-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Desc. Cliente</strong></td>
                                        <td>
                                            <v-text-field
                                                    class="staff_text-field_small"
                                                    v-model="inputConditions.descCli"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    @change="calculateInvoice"
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Desc. Pronto Pag.</strong></td>
                                        <td>
                                            <v-text-field
                                                    class="staff_text-field_small"
                                                    v-model="inputConditions.descPP"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    @change="calculateInvoice"
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                                <v-simple-table v-else class="staff_table">
                                    <tbody>
                                    <tr>
                                        <td><strong>Tipo Documento</strong></td>
                                        <td>
                                            <div v-if="pickOrder.tipoDocFat">
                                                {{ pickOrder.tipoDocFat }} {{
                                                    pickOrder.serieDocFat
                                                }}/{{ pickOrder.numDocFat }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Modo Pagamento</strong></td>
                                        <td>{{ pickOrder.modoPagamento }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Condição Pagamento</strong></td>
                                        <td>{{ showPaymentConditionName(pickOrder.condicaoPagamento) }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Desconto Cliente</strong></td>
                                        <td>{{ pickOrder.descontoCliente }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Desconto Pronto Pag.</strong></td>
                                        <td>{{ pickOrder.descontoPP }}</td>
                                    </tr>
                                    <tr  v-if="pickOrder.tipoDocFat">
                                        <td><strong>Transportadora</strong></td>
                                        <td>
                                            <div style="margin-top: 6px">
                                                <v-btn class="ma-1" depressed tile small
                                                       @click="configShippingDPD('auto')">DPD
                                                </v-btn>
                                                <v-btn class="ma-1" depressed tile small @click="configShippingREDUR()">
                                                    REDUR
                                                </v-btn>
                                                <v-btn class="ma-1" depressed tile small
                                                       @click="configShippingDPD('07287612')">BIKES
                                                </v-btn>
                                            </div>
                                            <div style="margin-bottom: 6px">
                                                <v-btn class="ma-1" depressed tile small @click="configShippingCTT()">
                                                    CTT
                                                </v-btn>
                                                <v-btn class="ma-1" depressed tile small @click="configShippingNacex()">
                                                    NACEX
                                                </v-btn>
                                                <v-btn class="ma-1" depressed tile small
                                                       @click="configShippingTransnautica()">
                                                    TRANSNAUTICA
                                                </v-btn>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="pickOrder.tipoDocFat">
                                        <td>Tracking Number</td>
                                        <td>{{ pickOrder.trackingNumber }}</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>

                            <v-col class="py-0">
                                <hr>
                                <v-simple-table class="staff_table">
                                    <tbody>
                                    <tr>
                                        <th style="width: 1px;">Obs.Cliente:</th>
                                        <td>{{ client.Notas }}</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="2">
                <v-card min-height="274px">
                    <v-card-text>
                        <v-simple-table class="total_table">
                            <tbody>
                            <tr>
                                <th>Merc</th>
                                <td class="text-right">{{ $money(doc.TotalMerc.replace(',', '.')) }}</td>
                            </tr>
                            <tr>
                                <th>Desconto</th>
                                <td class="text-right">{{ $money(doc.TotalDesc.replace(',', '.')) }}</td>
                            </tr>
                            <tr>
                                <th>IVA</th>
                                <td class="text-right">{{ $money(doc.TotalIva.replace(',', '.')) }}</td>
                            </tr>
                            <tr>
                                <th>Outros</th>
                                <td class="text-right">{{ $money(doc.TotalOutros.replace(',', '.')) }}</td>
                            </tr>
                            <tr>
                                <th>Sub-Total</th>
                                <td class="text-right">{{ $money(doc.TotalDocumento.replace(',', '.')) }}</td>
                            </tr>
                            <tr>
                                <th>Ecovalor</th>
                                <td class="text-right">{{ $money(doc.TotalEcotaxa.replace(',', '.')) }}</td>
                            </tr>
                            <tr>
                                <th style="font-size: 16px !important;">TOTAL</th>
                                <td style="font-size: 16px !important;" class="font-weight-bold text-right">
                                    <v-tooltip top
                                               v-if="client.LimiteCred - client.TotalDeb - doc.TotalDocumento.replace(',', '.') < 0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="red" v-bind="attrs" v-on="on">report_problem</v-icon>
                                        </template>
                                        <span>O cliente não tem crédito disponível para esta fatura</span>
                                    </v-tooltip>
                                    {{ $money(doc.TotalDocumento.replace(',', '.')) }}
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-row>
                        <v-col class="text-right pt-0 px-7">
                            <v-btn block tile v-if="pickOrder.numDocFat === null" color="success"
                                   @click="validateInvoice()">Faturar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

        </v-row>

        <!-- LISTA ARTIGOS -->
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-1" cols="9">
                                <template v-if="pickOrder.numDocFat === null">
                                    <v-btn tile small @click="toggleDialogAddNote()">
                                        Adicionar Comentário
                                    </v-btn>
                                    <v-btn tile small class="ml-3" @click="addShippingCosts()">
                                        Adicionar Portes
                                    </v-btn>
                                    <v-btn tile small class="ml-3" @click="addShippingExpenses()">
                                        Adicionar Cobrança
                                    </v-btn>
                                    <v-btn tile small class="ml-3" @click="addCustomsFees()">
                                        Adicionar Taxas Alfandegarias
                                    </v-btn>
                                    <v-btn tile small class="ml-3" @click="addExtraShippingCost()">
                                        Adicionar Custos Manipulação
                                    </v-btn>
                                </template>
                            </v-col>
                            <v-col class="text-right py-1">
                                <v-btn tile small color="info" @click="calculateInvoice()">Atualizar
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-simple-table class="staff_table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Artigo</th>
                                        <th>Cód.Barras</th>
                                        <th>Descrição</th>
                                        <th>Num. Série</th>
                                        <th>Lote</th>
                                        <th class="text-center">Arm</th>
                                        <th class="text-center">QTD</th>
                                        <th class="text-right">P.UNI</th>
                                        <th width="1" class="text-center">Desc1</th>
                                        <th width="1" class="text-center">Desc2</th>
                                        <th width="1" class="text-center">Desc3</th>
                                        <th class="text-center">C.IVA</th>
                                        <th class="text-center">V.IVA</th>
                                        <th class="text-right">LIQUIDO</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="note in notes"
                                        :style="[note.status === 'Apagado' ? {'text-decoration' : 'line-through'} : '']">
                                        <td>{{ note.numLinhaPri }}</td>
                                        <td colspan="2"></td>
                                        <td>
                                            {{ note.descricao }}
                                            <v-btn small
                                                   v-if="note.status !== 'Apagado' && pickOrder.numDocFat === null"
                                                   class="float-right red--text" icon
                                                   @click="updateStateNotes(note.id, note.status)">
                                                <v-icon>clear</v-icon>
                                            </v-btn>
                                            <v-btn small
                                                   v-if="note.status === 'Apagado' && pickOrder.numDocFat === null"
                                                   class="float-right green--text" icon
                                                   @click="updateStateNotes(note.id, note.status)">
                                                <v-icon>add</v-icon>
                                            </v-btn>
                                        </td>
                                        <td colspan="10"></td>
                                    </tr>
                                    <tr v-for="product in products">
                                        <td>{{ product.numLinhaPri }}<span v-if="isSuper"> - {{
                                                product.tipoLinha
                                            }}</span></td>
                                        <td>
                                            {{ product.artigo }}
                                            <v-btn v-if="product.artigo" icon small
                                                   :href="'/products/products/'+product.artigo"
                                                   target="_blank"
                                                   color="blue darken-2">
                                                <v-icon small>launch</v-icon>
                                            </v-btn>
                                        </td>
                                        <td>{{ product.codBarras }}</td>
                                        <td>{{ product.descricao }}</td>
                                        <td>{{ product.numSerie.join(', ') }}</td>
                                        <td>{{ product.lote }}</td>
                                        <td class="text-center">
                                            <v-select
                                                    v-model="product.arm"
                                                    :items="armOptions"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    style="width: 70px;"
                                                    class="staff_select_small"
                                                    v-if="product.tipoLinha !== 50 && product.tipoLinha !== 51 && pickOrder.numDocFat === null"
                                            ></v-select>
                                            <template v-if="pickOrder.numDocFat !== null && product.descricao !== 'Portes' && product.descricao !== 'Taxas Alfandegárias'
                                                && product.descricao !== 'Despesas de Cobrança' && product.descricao !== 'Despesas de Manuseamento'
                                                && product.descricao !== 'Coste de Manipulación'">
                                                {{ product.arm }}
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            <template v-if="product.tipoLinha !== 50 && product.tipoLinha !== 51">{{
                                                    product.qty
                                                }}
                                            </template>
                                        </td>
                                        <td class="text-right">
                                            <v-text-field
                                                    v-model="product.price"
                                                    outlined
                                                    hide-details
                                                    dense
                                                    v-if="(product.descricao === 'Portes' || product.descricao === 'Taxas Alfandegárias'
                                                || product.descricao === 'Despesas de Cobrança' || product.descricao === 'Despesas de Manuseamento'
                                                || product.descricao === 'Coste de Manipulación') && pickOrder.numDocFat === null"
                                                    style="width: 60px; margin-left: auto"
                                                    class="staff_text-field_small"
                                                    @keypress.enter="calculateInvoice"
                                            ></v-text-field>
                                            <template v-if="(product.descricao !== 'Portes' && product.descricao !== 'Taxas Alfandegárias'
                                                && product.descricao !== 'Despesas de Cobrança' && product.descricao !== 'Despesas de Manuseamento'
                                                && product.descricao !== 'Coste de Manipulación') || pickOrder.numDocFat !== null">
                                                {{ $money(product.price) }}
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            <template v-if="pickOrder.numDocFat === null">
                                                <v-text-field
                                                        v-model="product.desconto1"
                                                        outlined
                                                        hide-details
                                                        dense
                                                        style="width: 40px; margin-left: auto; margin-right: auto;"
                                                        class="staff_text-field_small"
                                                        v-if="product.descricao !== 'Portes' && product.descricao !== 'Taxas Alfandegárias'
                                                && product.descricao !== 'Despesas de Cobrança' && product.descricao !== 'Despesas de Manuseamento'
                                                && product.descricao !== 'Coste de Manipulación'"
                                                        @keypress.enter="calculateInvoice"
                                                ></v-text-field>
                                            </template>
                                            <template v-if="pickOrder.numDocFat !== null && product.descricao !== 'Portes' && product.descricao !== 'Taxas Alfandegárias'
                                                && product.descricao !== 'Despesas de Cobrança' && product.descricao !== 'Despesas de Manuseamento'
                                                && product.descricao !== 'Coste de Manipulación'">
                                                {{ product.desconto1 }}
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            <template v-if="pickOrder.numDocFat === null">
                                                <v-text-field
                                                        v-model="product.desconto2"
                                                        outlined
                                                        hide-details
                                                        dense
                                                        style="width: 40px; margin-left: auto; margin-right: auto;"
                                                        class="staff_text-field_small"
                                                        v-if="product.descricao !== 'Portes' && product.descricao !== 'Taxas Alfandegárias'
                                                && product.descricao !== 'Despesas de Cobrança' && product.descricao !== 'Despesas de Manuseamento'
                                                && product.descricao !== 'Coste de Manipulación'"
                                                        @keypress.enter="calculateInvoice"
                                                ></v-text-field>
                                            </template>
                                            <template v-if="pickOrder.numDocFat !== null && product.descricao !== 'Portes' && product.descricao !== 'Taxas Alfandegárias'
                                                && product.descricao !== 'Despesas de Cobrança' && product.descricao !== 'Despesas de Manuseamento'
                                                && product.descricao !== 'Coste de Manipulación'">
                                                {{ product.desconto2 }}
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            <template v-if="pickOrder.numDocFat === null">
                                                <v-text-field
                                                        v-model="product.desconto3"
                                                        outlined
                                                        hide-details
                                                        dense
                                                        style="width: 40px; margin-left: auto; margin-right: auto;"
                                                        class="staff_text-field_small"
                                                        v-if="product.descricao !== 'Portes' && product.descricao !== 'Taxas Alfandegárias'
                                                && product.descricao !== 'Despesas de Cobrança' && product.descricao !== 'Despesas de Manuseamento'
                                                && product.descricao !== 'Coste de Manipulación'"
                                                        @keypress.enter="calculateInvoice"
                                                ></v-text-field>
                                            </template>
                                            <template v-if="pickOrder.numDocFat !== null && product.descricao !== 'Portes' && product.descricao !== 'Taxas Alfandegárias'
                                                && product.descricao !== 'Despesas de Cobrança' && product.descricao !== 'Despesas de Manuseamento'
                                                && product.descricao !== 'Coste de Manipulación'">
                                                {{ product.desconto3 }}
                                            </template>
                                        </td>
                                        <td class="text-center">{{ product.codIVA }}</td>
                                        <td class="text-center">{{ $money(product.totalIVA) }}</td>
                                        <td class="text-right">{{ $money(product.totalLiq) }}</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">

            <v-dialog v-model="responseDialog.open" persistent max-width="700">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p>{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogAddNote" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Adicionar Comentário
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="inputNote"
                                outlined
                                dense
                                hide-details
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogAddNote = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="addNote">Adicionar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogTrackingNumber" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Tracking Number
                    </v-card-title>
                    <v-card-text class="text-center">
                        <strong>{{ ref.code }}</strong><br><br>
                        <input type="hidden" id="ref-code" :value="ref.code">
                        <v-btn @click="copyRefToClipboard()">Copiar código</v-btn>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="dialogTrackingNumber = false">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogLastDocs" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Últimos 10 documentos
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <tbody>
                            <tr>
                                <th>Data</th>
                                <th>Documento</th>
                                <th class="text-center">ModoPag</th>
                            </tr>
                            <tr v-for="doc in docs">
                                <td>{{ doc.Data.substring(0, 10) }}</td>
                                <td>{{ doc.TipoDoc }} {{ doc.Serie }} / {{ doc.NumDoc }}</td>
                                <td class="text-center">{{ doc.ModoPag }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="dialogLastDocs = false">
                            Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfigSEUR" persistent max-width="600">
                <v-card>
                    <v-card-title>
                        Configurar Envio Seur
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <!--                            {{ inputConfigShip }}-->
                            <v-row>
                                <v-col class="pt-0"><strong>Tracking Number: </strong></v-col>
                                <v-col class="pt-0">{{ ref.code }}</v-col>
                            </v-row>
                            <!--                            <p class="mb-0"><strong>Tracking Number: </strong>{{ ref.code }}</p>-->
                            <v-row>
                                <v-col class="pt-0"><strong>Morada de Entrega: </strong></v-col>
                                <v-col class="pt-0">
                                    <p class="mb-0">{{ inputConfigShip.address.Address }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Address2 }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Locality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Cp }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.CpLocality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Country }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <p class="ma-0"><strong>Tipo de Transporte:</strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="1" label="Normal"></v-radio>
                                        <v-radio value="17" label="Marítimo"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <!--                <v-col>
                                                  <p class="ma-0"><strong>Portes Debidos: </strong></p>
                                                  <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>
                                                    <v-radio value="0" :label="$t('no')"></v-radio>
                                                    <v-radio value="1" :label="$t('yes')"></v-radio>
                                                  </v-radio-group>
                                                </v-col>-->
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Volumes: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.quantity"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Peso: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.weight"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Contra-Reembolso: </strong>
                                </v-col>
                                <v-col>
                                    <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                           @click="updatePayment('yes')">
                                        Sim
                                    </v-btn>
                                    <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                           @click="updatePayment('no')">
                                        Não
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="inputConfigShip.payment === 'yes'">
                                <v-col>
                                    <strong>Preço a cobrar: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field dense v-model="inputConfigShip.price" outlined
                                                  style="width: 150px"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          style="margin-top: 10px"
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipSEUR()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingSEUR()" color="success" :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="dialogConfigNACEX" persistent max-width="600">
                <v-card>
                    <v-card-title>
                        Configurar Envio Nacex
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <!--                            {{ inputConfigShip }}-->
                            <v-row>
                                <v-col class="pt-0"><strong>Tracking Number: </strong></v-col>
                                <v-col class="pt-0">{{ ref.code }}</v-col>
                            </v-row>
                            <!--                            <p class="mb-0"><strong>Tracking Number: </strong>{{ ref.code }}</p>-->
                            <v-row>
                                <v-col class="pt-0"><strong>Morada de Entrega: </strong></v-col>
                                <v-col class="pt-0">
                                    <p class="mb-0">{{ inputConfigShip.address.Address }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Address2 }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Locality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Cp }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.CpLocality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Country }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <p class="ma-0"><strong>Tipo de Transporte:</strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="1" label="Normal"></v-radio>
                                        <v-radio value="17" label="Marítimo"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col>
                                    <p class="ma-0"><strong>Portes Debidos: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>
                                        <v-radio value="0" :label="$t('no')"></v-radio>
                                        <v-radio value="1" :label="$t('yes')"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Volumes: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.quantity"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Peso: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.weight"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Contra-Reembolso: </strong>
                                </v-col>
                                <v-col>
                                    <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                           @click="updatePayment('yes')">
                                        Sim
                                    </v-btn>
                                    <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                           @click="updatePayment('no')">
                                        Não
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="inputConfigShip.payment === 'yes'">
                                <v-col>
                                    <strong>Preço a cobrar: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field dense v-model="inputConfigShip.price" outlined
                                                  style="width: 150px"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          style="margin-top: 10px"
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipNacex()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingNacex()" color="success"
                               :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfigDELNEXT" persistent max-width="600">
                <v-card>
                    <v-card-title>
                        Configurar Envio Delnext
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <!--                            {{ inputConfigShip }}-->
                            <v-row>
                                <v-col class="pt-0"><strong>Tracking Number: </strong></v-col>
                                <v-col class="pt-0">{{ ref.code }}</v-col>
                            </v-row>
                            <!--                            <p class="mb-0"><strong>Tracking Number: </strong>{{ ref.code }}</p>-->
                            <v-row>
                                <v-col class="pt-0"><strong>Morada de Entrega: </strong></v-col>
                                <v-col class="pt-0">
                                    <p class="mb-0">{{ inputConfigShip.address.Address }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Address2 }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Locality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Cp }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.CpLocality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Country }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <p class="ma-0"><strong>Tipo de Transporte:</strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="1" :label="'PT Ilhas - Marítmo'"></v-radio>
                                        <v-radio value="2" :label="'PT Ilhas - Avião'"></v-radio>
                                        <v-radio value="3" :label="'PT Continental e ES' "></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col>
                                    <!--                  <p class="ma-0"><strong>Portes Debidos: </strong></p>-->
                                    <!--                  <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>-->
                                    <!--                    <v-radio value="0" :label="$t('no')"></v-radio>-->
                                    <!--                    <v-radio value="1" :label="$t('yes')"></v-radio>-->
                                    <!--                  </v-radio-group>-->
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Volumes: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.quantity"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Peso: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.weight"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Contra-Reembolso: </strong>
                                </v-col>
                                <v-col>
                                    <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                           @click="updatePayment('yes')">
                                        Sim
                                    </v-btn>
                                    <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                           @click="updatePayment('no')">
                                        Não
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="inputConfigShip.payment === 'yes'">
                                <v-col>
                                    <strong>Preço a cobrar: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field dense v-model="inputConfigShip.price" outlined
                                                  style="width: 150px"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          style="margin-top: 10px"
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipDelnext()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingDelnext()" color="success"
                               :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfigCTT" persistent max-width="600">
                <v-card>
                    <v-card-title>
                        Configurar Envio CTT
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <!--                            {{ inputConfigShip }}-->
                            <v-row>
                                <v-col class="pt-0"><strong>Tracking Number: </strong></v-col>
                                <v-col class="pt-0">{{ ref.code }}</v-col>
                            </v-row>
                            <!--                            <p class="mb-0"><strong>Tracking Number: </strong>{{ ref.code }}</p>-->
                            <v-row>
                                <v-col class="pt-0"><strong>Morada de Entrega: </strong></v-col>
                                <v-col class="pt-0">
                                    <p class="mb-0">{{ inputConfigShip.address.Address }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Address2 }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Locality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Cp }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.CpLocality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Country }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <p class="ma-0"><strong>Tipo de Serviço: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="EMSF010.01" :label="'PT Normal'"></v-radio>
                                        <v-radio value="ENCF008.01" :label="'PT Ilhas - Marítmo'"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <!--                <v-col>-->
                                <!--                  <p class="ma-0"><strong>Portes Debidos: </strong></p>-->
                                <!--                  <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>-->
                                <!--                    <v-radio value="0" :label="$t('no')"></v-radio>-->
                                <!--                    <v-radio value="1" :label="$t('yes')"></v-radio>-->
                                <!--                  </v-radio-group>-->
                                <!--                </v-col>-->
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Volumes: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.quantity"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Peso: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.weight"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Contra-Reembolso: </strong>
                                </v-col>
                                <v-col>
                                    <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                           @click="updatePayment('yes')">
                                        Sim
                                    </v-btn>
                                    <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                           @click="updatePayment('no')">
                                        Não
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="inputConfigShip.payment === 'yes'">
                                <v-col>
                                    <strong>Preço a cobrar: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field dense v-model="inputConfigShip.price" outlined
                                                  style="width: 150px"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          style="margin-top: 10px"
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipCTT()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingCTT()" color="success" :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="dialogDeporvillage" persistent max-width="400">
                <v-card>
                    <v-card-title>Etiqueta Deporvillage</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="inputDeporvillage"
                                outlined
                                hide-details
                                dense
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogDeporvillage = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" :disabled="inputDeporvillage === ''" :loading="loadingDeporvillage"
                               @click="printDeporvillage()">
                            Imprimir
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfigRedur" persistent max-width="600">
                <v-card>
                    <v-card-title>
                        Configurar Envio Redur
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <!--                            {{ inputConfigShip }}-->
                            <v-row>
                                <v-col class="pt-0"><strong>Tracking Number: </strong></v-col>
                                <v-col class="pt-0">{{ ref.code }}</v-col>
                            </v-row>
                            <!--                            <p class="mb-0"><strong>Tracking Number: </strong>{{ ref.code }}</p>-->
                            <v-row>
                                <v-col class="pt-0"><strong>Morada de Entrega: </strong></v-col>
                                <v-col class="pt-0">
                                    <p class="mb-0">{{ inputConfigShip.address.Address }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Address2 }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Locality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Cp }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.CpLocality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Country }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <!--                                <v-col>-->
                                <!--                                    <p class="ma-0"><strong>Tipo de Transporte:</strong></p>-->
                                <!--                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>-->
                                <!--                                        <v-radio value="1" label="Normal"></v-radio>-->
                                <!--                                        <v-radio value="17" label="Marítimo"></v-radio>-->
                                <!--                                    </v-radio-group>-->
                                <!--                                </v-col>-->
                                <v-col>
                                    <p class="ma-0"><strong>Portes Debidos: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>
                                        <v-radio value="0" :label="$t('no')"></v-radio>
                                        <v-radio value="1" :label="$t('yes')"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Volumes: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.quantity"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Peso: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.weight"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Contra-Reembolso: </strong>
                                </v-col>
                                <v-col>
                                    <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                           @click="updatePayment('yes')">
                                        Sim
                                    </v-btn>
                                    <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                           @click="updatePayment('no')">
                                        Não
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="inputConfigShip.payment === 'yes'">
                                <v-col>
                                    <strong>Preço a cobrar: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field dense v-model="inputConfigShip.price" outlined
                                                  style="width: 150px"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          style="margin-top: 10px"
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipRedur()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingRedur()" color="success"
                               :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="dialogConfigShippingLabel" persistent max-width="600">
                <v-card>
                    <v-card-title>
                        Configurar Envio {{ selectedCarrier }}
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <!--                            {{ inputConfigShip }}-->
                            <v-row>
                                <v-col class="pt-0"><strong>Tracking Number: </strong></v-col>
                                <v-col class="pt-0">{{ ref.code }}</v-col>
                            </v-row>
                            <!--                            <p class="mb-0"><strong>Tracking Number: </strong>{{ ref.code }}</p>-->
                            <v-row>
                                <v-col class="pt-0">
                                    <strong>Morada de Entrega: </strong>
                                    <v-btn color="primary" v-if="changeAddress == false" x-small style="float:right"
                                           @click="editShippingAddress(true)">Editar
                                    </v-btn>
                                    <v-btn color="error" v-else x-small style="float:right"
                                           @click="editShippingAddress(false)">Cancelar
                                    </v-btn>
                                </v-col>
                                <v-col class="pt-0" v-if="changeAddress == false">

                                    <p class="mb-0">{{ inputConfigShip.address.Address }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Address2 }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Locality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Cp }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.CpLocality }}</p>
                                    <p class="mb-0">{{ inputConfigShip.address.Country }}</p>
                                </v-col>
                                <v-col class="pt-0" v-else>
                                    <v-text-field dense v-model="inputConfigShip.name"
                                                  label="Nome do cliente" counter :maxlength="maxNameLength" outlined
                                                  :rules="[rules.required]"></v-text-field>
                                    <v-text-field dense v-model="inputConfigShip.address.Address" outlined
                                                  label="Morada 1" :counter="selectedCarrier=='Seur'"
                                                  :maxlength="selectedCarrier=='Seur' ? addressCharLimitSeur : addressCharLimit"></v-text-field>
                                    <v-text-field dense v-model="inputConfigShip.address.Address2" outlined
                                                  label="Morada 2" :counter="selectedCarrier=='Seur'"
                                                  :maxlength="selectedCarrier=='Seur' ? addressCharLimitSeur : addressCharLimit"></v-text-field>
                                    <v-text-field dense v-model="inputConfigShip.address.Locality" outlined
                                                  :rules="[rules.required]" label="Localidade"></v-text-field>
                                    <v-text-field dense v-model="inputConfigShip.address.Cp" outlined
                                                  :rules="[rules.required]" label="Código Postal"></v-text-field>
                                    <v-text-field dense v-model="inputConfigShip.address.CpLocality" outlined
                                                  :rules="[rules.required]"
                                                  label="Código Postal Localidade"></v-text-field>
                                    <v-text-field dense v-model="inputConfigShip.address.Country" v-if="changeAddress"
                                                  outlined
                                                  :rules="[rules.required]" label="Pais"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="selectedCarrier !== 'Redur' && selectedCarrier !== 'TRANSNAUTICA'">
                                    <p class="ma-0"><strong>Tipo de Transporte:</strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio v-for="option in shippingOptions" :key="option.value"
                                                 :value="option.value" :label="option.label"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col v-if="selectedCarrier !== 'Seur' && selectedCarrier !== 'CTT' && selectedCarrier !== 'Delnext' && selectedCarrier !== 'TRANSNAUTICA'">
                                    <p class="ma-0"><strong>Portes Debidos: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>
                                        <v-radio value="0" :label="$t('no')"></v-radio>
                                        <v-radio value="1" :label="$t('yes')"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Volumes: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.quantity"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Peso: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="inputConfigShip.weight"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Contra-Reembolso: </strong>
                                </v-col>
                                <v-col>
                                    <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                           @click="updatePayment('yes')">
                                        Sim
                                    </v-btn>
                                    <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                           @click="updatePayment('no')">
                                        Não
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="inputConfigShip.payment === 'yes'">
                                <v-col>
                                    <strong>Preço a cobrar: </strong>
                                </v-col>
                                <v-col>
                                    <v-text-field dense v-model="inputConfigShip.price" outlined
                                                  style="width: 150px"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          style="margin-top: 10px"
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipping()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmShippingLabel()" color="success"
                               :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogClientInfo" persistent max-width="600">
                <v-card>
                    <v-toolbar flat dense dark color="primary">
                        <v-toolbar-title class="text-uppercase">
                            Informação Cliente
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pt-2">
                        <v-row>
                            <v-col>
                                <strong>Bloqueado: </strong>
                                <v-btn tile x-small @click="editClient.TipoCred = '1'"
                                       :class="{'success': editClient.TipoCred === '1'}">Não
                                </v-btn>
                                <v-btn tile x-small @click="editClient.TipoCred = '2'"
                                       :class="{'error': editClient.TipoCred === '2'}">Sim
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="editClient.LimiteCred"
                                        label="Limite Crédito"
                                        outlined
                                        dense
                                        hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                        v-model="editClient.Notas"
                                        label="Notas do Cliente"
                                        outlined
                                        dense
                                        hide-details
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogClientInfo = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="saveClientInfo()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogCreditLimit" persistent max-width="400">
                <v-card>
                    <v-toolbar flat dense dark color="primary">
                        <v-toolbar-title class="text-uppercase">
                            Limite de Crédito excedido
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pb-0">
                        <p>O limite de crédito do cliente será excedido ao efetuar a fatura</p>
                        <p class="pb-0"><strong>Deseja alterar o limite de crédito para efetuar a fatura?</strong></p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="error" @click="dialogCreditLimit = false">Não</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="makeInvoice()">Sim</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogVatInfo" persistent max-width="400">
                <v-card>
                    <v-toolbar flat dense dark color="primary">
                        <v-toolbar-title class="text-uppercase">
                            Informação VIES
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pb-0">
                        <v-simple-table class="staff_table">
                            <tbody>
                            <tr>
                                <th>Nome</th>
                                <td>{{ vatInfo.name }}</td>
                            </tr>
                            <tr>
                                <th>Contribuinte</th>
                                <td>{{ vatInfo.vatNumber }}</td>
                            </tr>
                            <tr>
                                <th>Valido</th>
                                <td>
                                    <i class="fas fa-check" style="color: green" v-if="vatInfo.valid"></i>
                                    <i class="fas fa-times" style="color: red" v-if="!vatInfo.valid"></i>
                                </td>
                            </tr>
                            <tr>
                                <th>Morada</th>
                                <td>{{ vatInfo.address }}</td>
                            </tr>
                            <tr>
                                <th>Pais</th>
                                <td>{{ vatInfo.countryCode }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialogVatInfo = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>
    </v-container>
</template>

<script>

import User from "@/models/User";
import i18n from "../../plugins/i18n";

export default {
    data: function () {
        return {
            addressCharLimitSeur: 32,
            addressCharLimit: 100,
            changeAddress: false,
            originalAddress: {},
            rules: {
                required: value => !!value || value === '0' || i18n.t('required'),
            },
            shippingOptions: [],
            selectedCarrier: "",
            dialogConfigShippingLabel: false,
            pickOrderId: '',
            pickOrder: {},
            shippingAddress: [],
            products: [],
            paymentConditions: [],
            inputConditions: {
                modePag: '',
                condPag: '',
                typeDoc: '',
                descCli: '0',
                descPP: '0',
                carrier: ''
            },
            ivaOptions: [
                {'value': 'A', 'text': 'A (0)', 'taxa': 0},
                {'value': 'B', 'text': 'B (0)', 'taxa': 0},
                {'value': 'O', 'text': 'O (0)', 'taxa': 0},
                {'value': '06', 'text': '06 (6)', 'taxa': 6},
                {'value': '23', 'text': '23 (23)', 'taxa': 23},
            ],
            armOptions: [
                'A1', 'A2', 'A3', 'A3.1', 'A5', 'A51', 'F1', 'G1', 'T1', 'V1', 'V2'
            ],
            totals: {
                'merc': 0,
                'desconto': 0,
                'iva': 0,
                'outros': 0,
                'sub_total': 0,
                'acerto': 0,
                'ecovalor': 0,
                'total': 0
            },
            inputPortes: {
                qty: 1,
                price: '',
                iva: 23,
                vIVA: 0,
                liq: 0
            },
            stock: {},
            activeTab: 1,
            notes: [],
            doc: {
                TotalMerc: '0',
                TotalDesc: '0',
                TotalIva: '0',
                TotalOutros: '0',
                TotalDocumento: '0',
                TotalEcotaxa: '0',
            },
            documento: {},
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
            dialogAddNote: false,
            dialogLastDocs: false,
            dialogTrackingNumber: false,
            inputNote: '',
            option: '',
            inputConfigShip: {
                name: '',
                price: '',
                address: {
                    Address: '',
                    Address2: '',
                    Locality: '',
                    Cp: '',
                    CpLocality: '',
                    Country: ''
                },
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                type: '1',
                portesDebidos: 0,
                account: 'auto'
            },
            maxNameLength: 32,
            ref: {},
            dialogConfigSEUR: false,
            dialogConfigRedur: false,
            dialogConfigNACEX: false,
            dialogConfigDELNEXT: false,
            dialogConfigCTT: false,
            docs: [],
            client: {
                TipoCred: '1',
                LimiteCred: 0,
                TotalDeb: 0,
            },
            tabAddress: 0,
            dialogDeporvillage: false,
            inputDeporvillage: '',
            loadingDeporvillage: false,
            addresses: [],
            dialogClientInfo: false,
            editClient: {},
            dialogCreditLimit: false,
            vatInfo: {},
            dialogVatInfo: false,
            validVat: ""
        }
    },
    computed: {
        isSuper: function () {
            return User.getters.getProfileId === 1
        },
    },
    created() {
        this.pickOrderId = this.$route.params.id;
        document.title = 'Faturação Picking - ' + this.pickOrderId + ' | STAFF';
        this.getInvoicePickOrder();
    },
    methods: {
        getInvoicePickOrder() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.pickOrder = responseData.pickOrder
                            this.products = responseData.products
                            this.shippingAddress = responseData.shippingAddress
                            this.paymentConditions = responseData.paymentConditions
                            this.notes = responseData.notes
                            this.docs = responseData.docs
                            this.client = responseData.client
                            this.addresses = responseData.addresses
                            this.inputConditions.modePag = this.pickOrder.modoPagamento
                            this.inputConditions.condPag = this.pickOrder.condicaoPagamento
                            this.inputConditions.descCli = this.pickOrder.descontoCliente
                            this.tabAddress = 1
                            let condPag = this.paymentConditions.filter(item => item.CondPag === this.inputConditions.condPag)
                            this.inputConditions.descPP = condPag[0].Desconto;
                            this.validVat = responseData.vat.status;
                            this.updateTypeDoc()
                            this.calculateInvoice()
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        updateTypeDoc() {
            this.inputConditions.typeDoc = 'FA'
            if (this.inputConditions.modePag === 'RTB' || this.inputConditions.modePag === 'CNTRE') {
                this.inputConditions.typeDoc = 'FAP'
            }
            if ((this.pickOrder.cliente == 'VDSHOPES' || this.pickOrder.cliente == 'VDSHOP') && this.pickOrder.Pais_EN != 'PT') {
                this.inputConditions.typeDoc = 'FAPO'
            }
        },
        getStockProduct(product) {
            this.stock = {};
            if (product !== '') {
                let request = {
                    url: '/api/staff/pick/product/' + product + '/stock'
                }

                ApiWS.dispatch('makeGet', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.stock = responseData.stock;
                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        updateStateNotes(idLinePri, status) {
            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/updateStateNotes',
                data: {
                    'idLinePri': idLinePri,
                    'state': status
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.notes = responseData.notes;
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        calculateInvoice() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/preview',
                data: {
                    products: this.products,
                    conditions: this.inputConditions,
                    client: this.pickOrder.cliente
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.doc = responseData.doc;

                            let linhas = this.doc.Linhas;

                            for (let i = 0; i < linhas.length; i++) {
                                for (let j = 0; j < this.products.length; j++) {
                                    linhas[i].TotalIva = linhas[i].TotalIva.replace(",", ".");
                                    linhas[i].Preco = linhas[i].Preco.replace(",", ".");
                                    linhas[i].Desconto = linhas[i].Desconto.replace(",", ".");

                                    if (linhas[i].Codigo === this.products[j].artigo && linhas[i].Descricao !== 'Portes'
                                            && linhas[i].Descricao !== 'Taxas Alfandegárias'
                                            && linhas[i].Descricao !== 'Despesas de Cobrança'
                                            && linhas[i].Descricao !== 'Coste de Manipulación'
                                            && linhas[i].Descricao !== 'Despesas de Manuseamento' && linhas[i].Desconto == this.products[j].desconto1) {
                                        this.products[j].totalIVA = linhas[i].TotalIva;
                                        this.products[j].totalLiq = linhas[i].Preco;
                                    }


                                    if (linhas[i].Codigo === '' && linhas[i].Descricao === this.products[j].descricao) {
                                        this.products[j].totalIVA = linhas[i].TotalIva;
                                        this.products[j].totalLiq = linhas[i].Preco;
                                    }
                                }
                            }
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        makeInvoice() {
            let loader = this.$loading.show();
            let changeCreditLimit = this.client.LimiteCred - this.client.TotalDeb - this.doc.TotalDocumento.replace(',', '.') < 0;
            this.dialogCreditLimit = false;

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/invoice',
                data: {
                    products: this.products,
                    conditions: this.inputConditions,
                    client: this.pickOrder.cliente,
                    addressId: this.shippingAddress.Id,
                    notes: this.notes,
                    pickOrder: this.pickOrder,
                    shippingAddress: this.shippingAddress,
                    contribuinte: this.pickOrder.hasOwnProperty('NumContribuinte') ? this.pickOrder.NumContribuinte : '',
                    nome: this.pickOrder.hasOwnProperty('Nome') ? this.pickOrder.Nome : '',
                    changeCreditLimit: changeCreditLimit,
                    creditLimit: this.client.LimiteCred
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.documento = responseData.doc;
                            this.responseDialog.open = true;
                            this.responseDialog.title = 'Faturação';
                            this.responseDialog.message = responseData.message;
                            this.responseDialog.error = false;
                            this.pickOrder = responseData.pickOrder;
                            this.option = 'Faturado';
                        } else {
                            this.responseDialog.open = true;
                            this.responseDialog.title = 'Faturação';
                            this.responseDialog.message = responseData.message;
                            this.responseDialog.error = true;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        toggleDialogAddNote() {
            this.inputNote = '';
            this.dialogAddNote = true;
        },
        addNote() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/note',
                data: {
                    note: this.inputNote
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.notes = responseData.notes;
                            this.dialogAddNote = false;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        createShippingSEUR() {
            if (this.$refs.form.validate()) {
                let loader = this.$loading.show();

                let shipping = {
                    client: {
                        entity: this.pickOrder.cliente,
                        name: this.inputConfigShip.name,
                        tel: this.pickOrder.hasOwnProperty('Fac_Tel') ? this.pickOrder.Fac_Tel : '',
                        email: this.client.CDU_LOGIN
                    },
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: [{
                        id: this.pickOrder.idDocFat,
                        type: this.pickOrder.tipoDocFat,
                        number: this.pickOrder.numDocFat,
                        serie: this.pickOrder.serieDocFat,
                        total_doc: this.pickOrder.totalDoc,
                        vendedor: this.pickOrder.vendedor
                    }],
                    pickOrderId: this.pickOrderId,
                    courier: 'SEUR2'
                }

                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)

                                this.dialogConfigShippingLabel = false;
                                //this.dialogConfigSEUR = false;
                                this.updateTrackingNumber('SEUR2');
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                            loader.hide();
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide();
                        })
            }
        },
        createShippingRedur() {
            if (this.$refs.form.validate()) {
                let loader = this.$loading.show();

                let shipping = {
                    client: {
                        entity: this.pickOrder.cliente,
                        name: this.inputConfigShip.name,
                        tel: this.pickOrder.hasOwnProperty('Fac_Tel') ? this.pickOrder.Fac_Tel : '',
                        email: this.client.CDU_LOGIN
                    },
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: [{
                        id: this.pickOrder.idDocFat,
                        type: this.pickOrder.tipoDocFat,
                        number: this.pickOrder.numDocFat,
                        serie: this.pickOrder.serieDocFat,
                        total_doc: this.pickOrder.totalDoc,
                        vendedor: this.pickOrder.vendedor
                    }],
                    pickOrderId: this.pickOrderId,
                    courier: 'REDUR'
                }

                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)

                                this.dialogConfigShippingLabel = false;
                                //this.dialogConfigRedur = false;
                                this.updateTrackingNumber('REDUR');
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                            loader.hide();
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide();
                        })
            }
        },
        createShippingCTT() {
            if (this.$refs.form.validate()) {
                let loader = this.$loading.show();

                let shipping = {
                    client: {
                        entity: this.pickOrder.cliente,
                        name: this.inputConfigShip.name,
                        tel: this.pickOrder.hasOwnProperty('Fac_Tel') ? this.pickOrder.Fac_Tel : '',
                        email: this.client.CDU_LOGIN
                    },
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: [{
                        id: this.pickOrder.idDocFat,
                        type: this.pickOrder.tipoDocFat,
                        number: this.pickOrder.numDocFat,
                        serie: this.pickOrder.serieDocFat,
                        total_doc: this.pickOrder.totalDoc,
                        vendedor: this.pickOrder.vendedor
                    }],
                    pickOrderId: this.pickOrderId,
                    courier: 'CTT'
                }

                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)

                                this.dialogConfigShippingLabel = false;
                                //this.dialogConfigCTT = false;
                                this.updateTrackingNumber('CTT');
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                            loader.hide();
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide();
                        })
            }
        },
        createShippingDelnext() {
            if (this.$refs.form.validate()) {
                let loader = this.$loading.show();

                let shipping = {
                    client: {
                        entity: this.pickOrder.cliente,
                        name: this.inputConfigShip.name,
                        tel: this.pickOrder.hasOwnProperty('Fac_Tel') ? this.pickOrder.Fac_Tel : '',
                        email: this.client.CDU_LOGIN
                    },
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: [{
                        id: this.pickOrder.idDocFat,
                        type: this.pickOrder.tipoDocFat,
                        number: this.pickOrder.numDocFat,
                        serie: this.pickOrder.serieDocFat,
                        total_doc: this.pickOrder.totalDoc,
                        vendedor: this.pickOrder.vendedor
                    }],
                    pickOrderId: this.pickOrderId,
                    courier: 'DELNEXT'
                }

                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)

                                this.dialogConfigShippingLabel = false;
                                //this.dialogConfigDELNEXT = false;
                                this.updateTrackingNumber('DELNEXT');

                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                            loader.hide();
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide();
                        })
            }
        },
        createShippingNacex() {
            if (this.$refs.form.validate()) {
                let loader = this.$loading.show();

                let shipping = {
                    client: {
                        entity: this.pickOrder.cliente,
                        name:  this.inputConfigShip.name,
                        tel: this.pickOrder.hasOwnProperty('Fac_Tel') ? this.pickOrder.Fac_Tel : '',
                        email: this.client.CDU_LOGIN
                    },
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: [{
                        id: this.pickOrder.idDocFat,
                        type: this.pickOrder.tipoDocFat,
                        number: this.pickOrder.numDocFat,
                        serie: this.pickOrder.serieDocFat,
                        total_doc: this.pickOrder.totalDoc,
                        vendedor: this.pickOrder.vendedor
                    }],
                    pickOrderId: this.pickOrderId,
                    courier: 'NACEX'
                }

                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)

                                this.dialogConfigShippingLabel = false;
                                //this.dialogConfigNACEX = false;
                                this.updateTrackingNumber('NACEX');
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                            loader.hide();
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide();
                        })
            }
        },
        createShippingTransnautica() {
            if (this.$refs.form.validate()) {
                let loader = this.$loading.show();

                let shipping = {
                    client: {
                        entity: this.pickOrder.cliente,
                        name:  this.inputConfigShip.name,
                        tel: this.pickOrder.hasOwnProperty('Fac_Tel') ? this.pickOrder.Fac_Tel : '',
                        email: this.client.CDU_LOGIN
                    },
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: [{
                        id: this.pickOrder.idDocFat,
                        type: this.pickOrder.tipoDocFat,
                        number: this.pickOrder.numDocFat,
                        serie: this.pickOrder.serieDocFat,
                        total_doc: this.pickOrder.totalDoc,
                        vendedor: this.pickOrder.vendedor
                    }],
                    pickOrderId: this.pickOrderId,
                    courier: 'TRANSNAUTICA'
                }

                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)

                                this.dialogConfigShippingLabel = false;
                                this.updateTrackingNumber('TRANSNAUTICA');
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                            loader.hide();
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide();
                        })
            }
        },
        configShippingDelnext() {
            let order = {
                courier: 'DELNEXT',
                clientCode: this.pickOrder.cliente,
                clientName: this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome,
                salesman: this.pickOrder.vendedor
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {

                            //this.toggleDialogConfigShipDelnext();

                            this.selectedCarrier = "Delnext";
                            this.shippingOptions = [
                                {value: '1', label: 'PT Ilhas - Marítmo'},
                                {value: '2', label: 'PT Ilhas - Avião'},
                                {value: '3', label: 'PT Continental e ES'}
                            ];
                            this.toggleDialogConfigShipping();


                            this.ref = responseData.ref;
                            this.inputConfigShip.account = '';
                            this.inputConfigShip.name = this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome;
                            this.inputConfigShip.price = this.pickOrder.totalDoc;
                            this.inputConfigShip.quantity = this.pickOrder.volumes;
                            this.inputConfigShip.weight = this.pickOrder.peso;
                            this.inputConfigShip.payment = this.pickOrder.modoPagamento === 'CNTRE' ? 'yes' : 'no';

                            this.inputConfigShip.address = {
                                Address: this.shippingAddress.Fac_Mor_EN,
                                Address2: this.shippingAddress.Fac_Mor2_EN,
                                Locality: this.shippingAddress.Fac_Local_EN,
                                Cp: this.shippingAddress.Fac_Cp_EN,
                                CpLocality: this.shippingAddress.Fac_CpLocal_EN,
                                Country: this.shippingAddress.Pais_EN
                            }
                            // this.selectedCarrier = "Delnext";
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        configShippingNacex() {
            let order = {
                courier: 'NACEX',
                clientCode: this.pickOrder.cliente,
                clientName: this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome,
                salesman: this.pickOrder.vendedor
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {

                            //this.toggleDialogConfigShipNacex();
                            this.selectedCarrier = "Nacex";
                            this.shippingOptions = [
                                {value: '1', label: 'Normal'},
                                {value: '17', label: 'Marítimo'},
                            ];
                            this.toggleDialogConfigShipping();


                            this.ref = responseData.ref;
                            this.inputConfigShip.account = '';
                            this.inputConfigShip.name = this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome;
                            this.inputConfigShip.price = this.pickOrder.totalDoc;
                            this.inputConfigShip.quantity = this.pickOrder.volumes;
                            this.inputConfigShip.weight = this.pickOrder.peso;
                            this.inputConfigShip.payment = this.pickOrder.modoPagamento === 'CNTRE' ? 'yes' : 'no';

                            this.inputConfigShip.address = {
                                Address: this.shippingAddress.Fac_Mor_EN,
                                Address2: this.shippingAddress.Fac_Mor2_EN,
                                Locality: this.shippingAddress.Fac_Local_EN,
                                Cp: this.shippingAddress.Fac_Cp_EN,
                                CpLocality: this.shippingAddress.Fac_CpLocal_EN,
                                Country: this.shippingAddress.Pais_EN
                            }
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        configShippingCTT() {
            let order = {
                courier: 'CTT',
                clientCode: this.pickOrder.cliente,
                clientName: this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome,
                salesman: this.pickOrder.vendedor
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {

                            //this.toggleDialogConfigShipCTT();

                            this.selectedCarrier = "CTT";
                            this.shippingOptions = [
                                {value: 'EMSF010.01', label: 'PT Normal'},
                                {value: 'ENCF008.01', label: 'PT Ilhas - Marítmo'},
                            ];
                            this.toggleDialogConfigShipping();


                            this.ref = responseData.ref;
                            this.inputConfigShip.account = '';
                            this.inputConfigShip.name = this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome;
                            this.inputConfigShip.price = this.pickOrder.totalDoc;
                            this.inputConfigShip.quantity = this.pickOrder.volumes;
                            this.inputConfigShip.weight = this.pickOrder.peso;
                            this.inputConfigShip.type = 'EMSF010.01';
                            this.inputConfigShip.payment = this.pickOrder.modoPagamento === 'CNTRE' ? 'yes' : 'no';

                            this.inputConfigShip.address = {
                                Address: this.shippingAddress.Fac_Mor_EN,
                                Address2: this.shippingAddress.Fac_Mor2_EN,
                                Locality: this.shippingAddress.Fac_Local_EN,
                                Cp: this.shippingAddress.Fac_Cp_EN,
                                CpLocality: this.shippingAddress.Fac_CpLocal_EN,
                                Country: this.shippingAddress.Pais_EN
                            }
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        configShippingREDUR() {
            let order = {
                courier: 'REDUR',
                clientCode: this.pickOrder.cliente,
                clientName: this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome,
                salesman: this.pickOrder.vendedor
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {

                            //this.toggleDialogConfigShipRedur();

                            this.selectedCarrier = "Redur";
                            this.toggleDialogConfigShipping();

                            this.ref = responseData.ref;
                            this.inputConfigShip.account = '';
                            this.inputConfigShip.name = this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome;
                            this.inputConfigShip.price = this.pickOrder.totalDoc;
                            this.inputConfigShip.quantity = this.pickOrder.volumes;
                            this.inputConfigShip.weight = this.pickOrder.peso;
                            this.inputConfigShip.payment = this.pickOrder.modoPagamento === 'CNTRE' ? 'yes' : 'no';

                            this.inputConfigShip.address = {
                                Address: this.shippingAddress.Fac_Mor_EN,
                                Address2: this.shippingAddress.Fac_Mor2_EN,
                                Locality: this.shippingAddress.Fac_Local_EN,
                                Cp: this.shippingAddress.Fac_Cp_EN,
                                CpLocality: this.shippingAddress.Fac_CpLocal_EN,
                                Country: this.shippingAddress.Pais_EN
                            }
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        configShippingTransnautica() {
            let order = {
                courier: 'TRANSNAUTICA',
                clientCode: this.pickOrder.cliente,
                clientName: this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome,
                salesman: this.pickOrder.vendedor
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {

                            //this.toggleDialogConfigShipRedur();

                            this.selectedCarrier = "TRANSNAUTICA";
                            this.toggleDialogConfigShipping();

                            this.ref = responseData.ref;
                            this.inputConfigShip.account = '';
                            this.inputConfigShip.name = this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome;
                            this.inputConfigShip.price = this.pickOrder.totalDoc;
                            this.inputConfigShip.quantity = this.pickOrder.volumes;
                            this.inputConfigShip.weight = this.pickOrder.peso;
                            this.inputConfigShip.payment = this.pickOrder.modoPagamento === 'CNTRE' ? 'yes' : 'no';

                            this.inputConfigShip.address = {
                                Address: this.shippingAddress.Fac_Mor_EN,
                                Address2: this.shippingAddress.Fac_Mor2_EN,
                                Locality: this.shippingAddress.Fac_Local_EN,
                                Cp: this.shippingAddress.Fac_Cp_EN,
                                CpLocality: this.shippingAddress.Fac_CpLocal_EN,
                                Country: this.shippingAddress.Pais_EN
                            }
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        copyRefToClipboard() {
            let refCodeToCopy = document.querySelector('#ref-code')
            refCodeToCopy.setAttribute('type', 'text')
            refCodeToCopy.select()

            document.execCommand('copy')

            refCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        configShippingDPD(account) {
            let order = {
                courier: 'SEUR2',
                clientCode: this.pickOrder.cliente,
                clientName: this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome,
                salesman: this.pickOrder.vendedor
            };

            let loader = this.$loading.show();

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {


                            //this.toggleDialogConfigShipSEUR();

                            this.selectedCarrier = "Seur";
                            this.shippingOptions = [
                                {value: '1', label: 'Normal'},
                                {value: '17', label: 'Marítimo'},
                            ];
                            this.toggleDialogConfigShipping();

                            // this.getDataCustomerToShip();
                            this.ref = responseData.ref;
                            this.inputConfigShip.account = account;
                            this.inputConfigShip.name = this.pickOrder.cliente === 'VD' || this.pickOrder.cliente === 'VDES' || this.pickOrder.cliente === 'VDSHOP' || this.pickOrder.cliente === 'VDSHOPES' ? this.pickOrder.Nome : this.pickOrder.nome;
                            this.inputConfigShip.price = this.pickOrder.totalDoc;
                            this.inputConfigShip.quantity = this.pickOrder.volumes;
                            this.inputConfigShip.weight = this.pickOrder.peso;
                            this.inputConfigShip.payment = this.pickOrder.modoPagamento === 'CNTRE' ? 'yes' : 'no';

                            //Address
                            // this.inputConfigShip.address.Address = this.shippingAddress.Fac_Mor_EN;
                            // this.inputConfigShip.address.Address2 = this.shippingAddress.Fac_Mor2_EN;
                            // this.inputConfigShip.address.Locality = this.shippingAddress.Fac_Local_EN;
                            // this.inputConfigShip.address.Cp = this.shippingAddress.Fac_Cp_EN;
                            // this.inputConfigShip.address.CpLocality = this.shippingAddress.Fac_CpLocal_EN;
                            // this.inputConfigShip.address.Country = this.shippingAddress.Pais_EN;

                            this.inputConfigShip.address = {
                                Address: this.shippingAddress.Fac_Mor_EN,
                                Address2: this.shippingAddress.Fac_Mor2_EN,
                                Locality: this.shippingAddress.Fac_Local_EN,
                                Cp: this.shippingAddress.Fac_Cp_EN,
                                CpLocality: this.shippingAddress.Fac_CpLocal_EN,
                                Country: this.shippingAddress.Pais_EN
                            }
                            loader.hide();
                        }
                    })
                    .catch(response => {
                        loader.hide();
                    })
        },
        toggleDialogConfigShipSEUR() {
            this.dialogConfigSEUR = !this.dialogConfigSEUR;
            this.ref = {};
            this.inputConfigShip = {
                name: this.pickOrder.nome,
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                account: 'auto'
            };

            if (!this.dialogConfigSEUR) {
                this.$refs.form.resetValidation()
            }
        },
        toggleDialogConfigShipRedur() {
            this.dialogConfigRedur = !this.dialogConfigRedur;
            this.ref = {};
            this.inputConfigShip = {
                name: this.pickOrder.nome,
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                account: ''
            };

            if (!this.dialogConfigRedur) {
                this.$refs.form.resetValidation()
            }
        },
        toggleDialogConfigShipCTT() {
            this.dialogConfigCTT = !this.dialogConfigCTT;
            this.ref = {};
            this.inputConfigShip = {
                name: this.pickOrder.nome,
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                account: ''
            };

            if (!this.dialogConfigCTT) {
                this.$refs.form.resetValidation()
            }
        },
        toggleDialogConfigShipDelnext() {
            this.dialogConfigDELNEXT = !this.dialogConfigDELNEXT;
            this.ref = {};
            this.inputConfigShip = {
                name: this.pickOrder.nome,
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                account: '',
                type: '3'
            };

            if (!this.dialogConfigDELNEXT) {
                this.$refs.form.resetValidation()
            }
        },
        toggleDialogConfigShipNacex() {
            this.dialogConfigNACEX = !this.dialogConfigNACEX;
            this.ref = {};
            this.inputConfigShip = {
                name: this.pickOrder.nome,
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                account: ''
            };

            if (!this.dialogConfigNACEX) {
                this.$refs.form.resetValidation()
            }
        },
        updateTrackingNumber(courier) {
            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/trackingNumber',
                data: {
                    courier: courier,
                    tracking: this.ref.code
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.getInvoicePickOrder();
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao atualizar tracking number!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        closeInvoice() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/closeInvoice',
                data: {
                    pickOrder: this.pickOrder
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.$router.push('/orders/pick');
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        showPaymentConditionName(paymentCondition) {
            let paymentConditionName = ''
            this.paymentConditions.forEach(function (element) {
                if (paymentCondition === element.CondPag) {
                    paymentConditionName = element.Descricao
                }
            })
            return paymentConditionName
        },
        printInvoice() {
            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/printInvoice',
                data: {
                    numDoc: this.pickOrder.numDocFat,
                    tipoDoc: this.pickOrder.tipoDocFat,
                    serie: this.pickOrder.serieDocFat
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'A imprimir o documento!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao imprimir o documento!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        updatePayment(option) {
            this.inputConfigShip.payment = option
        },
        showLastDocs() {
            this.dialogLastDocs = true
        },
        changeCondPag() {
            let condPag = this.paymentConditions.filter(item => item.CondPag === this.inputConditions.condPag);
            console.log((condPag));
            this.inputConditions.descPP = condPag[0].Desconto
            this.calculateInvoice()
        },
        addShippingCosts() {
            let loader = this.$loading.show();
            let portes = [];
            portes.push({'descricao': 'Portes', 'price': 6, 'tipoLinha': 50});

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/preview',
                data: {
                    products: portes,
                    conditions: this.inputConditions,
                    client: this.pickOrder.cliente
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.doc = responseData.doc;

                            let linhas = this.doc.Linhas;

                            if (linhas.length === 1) {
                                this.insertShippingCost(linhas[0], 50);
                            }
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        toggleDialogDeporvillage() {
            this.dialogDeporvillage = true;
        },
        printDeporvillage() {
            this.loadingDeporvillage = true;
            let request = {
                url: api.orderPrintLabelDeporvillage(),
                data: {
                    barcode: this.inputDeporvillage
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'A imprimir etiqueta Deporvillage!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro a imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                        this.loadingDeporvillage = false;
                        this.dialogDeporvillage = false;
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingDeporvillage = false;
                    })
        },
        insertShippingCost(line, typeLine) {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/addShippingCosts',
                data: {
                    line: line,
                    typeLine: typeLine
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.products = responseData.products;
                            this.calculateInvoice();
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        addShippingExpenses() {
            let loader = this.$loading.show();
            let portes = [];
            portes.push({'descricao': 'Despesas de Cobrança', 'price': 3, 'tipoLinha': 51});

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/preview',
                data: {
                    products: portes,
                    conditions: this.inputConditions,
                    client: this.pickOrder.cliente
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.doc = responseData.doc;

                            let linhas = this.doc.Linhas;

                            if (linhas.length === 1) {
                                this.insertShippingCost(linhas[0], 51);
                            }
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        addCustomsFees() {
            let loader = this.$loading.show();
            let portes = [];
            portes.push({'descricao': 'Taxas Alfandegárias', 'price': 1, 'tipoLinha': 51});

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/preview',
                data: {
                    products: portes,
                    conditions: this.inputConditions,
                    client: this.pickOrder.cliente
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.doc = responseData.doc;

                            let linhas = this.doc.Linhas;

                            if (linhas.length === 1) {
                                this.insertShippingCost(linhas[0], 51);
                            }
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        addExtraShippingCost() {
            let loader = this.$loading.show();
            let portes = [];
            portes.push({'descricao': 'Coste de Manipulación', 'price': 10, 'tipoLinha': 51});

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/preview',
                data: {
                    products: portes,
                    conditions: this.inputConditions,
                    client: this.pickOrder.cliente
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.doc = responseData.doc;

                            let linhas = this.doc.Linhas;

                            if (linhas.length === 1) {
                                this.insertShippingCost(linhas[0], 51);
                            }
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        updateShippingAddress() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/changeShippingAddress',
                data: {
                    shippingAddressId: this.shippingAddress.Id
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.shippingAddress = responseData.shippingAddress;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        toggleDialogClientInfo() {
            this.editClient = this.client;
            this.dialogClientInfo = true;
        },
        saveClientInfo() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/invoice/' + this.pickOrderId + '/clientInfo',
                data: {
                    clientInfo: this.editClient
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.dialogClientInfo = false;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        validateInvoice() {
            let creditLimitFinal = this.client.LimiteCred - this.client.TotalDeb - this.doc.TotalDocumento.replace(',', '.');
            if (creditLimitFinal < 0) {
                this.dialogCreditLimit = true;
            } else {
                this.makeInvoice();
            }
        },
        toggleDialogValidateVies() {
            this.dialogVatInfo = true;

            let request = {
                url: '/api/staff/clients/validateVies',
                data: {
                    country: this.client.Pais,
                    vatNumber: this.client.NumContrib
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.vatInfo = responseData.vatInfo;
                        }
                    })
        },
        confirmShippingLabel() {
            if (this.selectedCarrier === 'Nacex')
                this.createShippingNacex();
            else if (this.selectedCarrier === 'Redur')
                this.createShippingRedur();
            else if (this.selectedCarrier === 'Seur')
                this.createShippingSEUR();
            else if (this.selectedCarrier === 'Delnext')
                this.createShippingDelnext();
            else if (this.selectedCarrier === 'CTT')
                this.createShippingCTT();
            else if (this.selectedCarrier === 'TRANSNAUTICA')
                this.createShippingTransnautica();
        },
        toggleDialogConfigShipping() {
            this.changeAddress = false;
            this.dialogConfigShippingLabel = !this.dialogConfigShippingLabel;
            this.ref = {};
            this.inputConfigShip = {
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                type: '',
                portesDebidos: 0,
                account: ''
            };

            this.maxNameLength = 32;
            if (this.selectedCarrier == 'Delnext')
                this.inputConfigShip.type = '3';
            else if (this.selectedCarrier == 'Seur') {
                this.inputConfigShip.account = 'auto';
                this.maxNameLength = 21;
            }

            if (!this.dialogConfigShippingLabel) {
                this.$refs.form.resetValidation()
            }
        },
        editShippingAddress(open) {
            if (open == true) {
                this.originalAddress = JSON.stringify(this.inputConfigShip.address);
                this.changeAddress = true;
            } else {
                this.inputConfigShip.address = JSON.parse(this.originalAddress);
                this.changeAddress = false;
            }
        }
    }
}
</script>
