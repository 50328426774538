<template>
    <div>
        <v-card tile min-height="300">
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="4">
                        <v-autocomplete
                                v-model="productExtraToAdd"
                                :items="productsList"
                                item-text="Artigo"
                                item-value="Artigo"
                                :label="$t('product')"
                                append-icon="search"
                                outlined
                                dense
                                hide-details
                                clearable
                                @keypress.enter="dialogAddExtraProduct = true"
                                @focus="$event.target.select()"
                                ref="fastInsertProduct"
                        >
                            <template slot="item" slot-scope="data">
                                {{ data.item.Artigo }} - {{ data.item.Descricao }}
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="2">
                        <v-btn tile color="success" @click="dialogAddExtraProduct = true">
                            {{ $t('add') }} {{ $t('product') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- EXTRA PRODUCTS TABLE -->
                <v-row v-if="productExtras.length > 0">
                    <v-col>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th class="pl-2">{{ $t('product') }}</th>
                                <th class="text-center">Quantidade</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="product in this.productExtras">
                                <td class="pl-3">
                                    {{ product.extraProduct }}
                                    <a :href="'/products/products/'+product.extraProduct" target="_blank" class="ml-3">
                                        <i class="fas fa-fw fa-external-link-alt"></i>
                                    </a>
                                </td>
                                <td class="text-center">{{ product.quantity }}</td>
                                <td class="text-right">
                                    <v-btn tile small color="error" class="mb-1 mt-1"
                                           @click="dialogRemoveExtraProduct=true;productExtraToRemove=product.extraProduct;">
                                        Remover
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>


        <!-- DIALOG ADD CONFIRM ADD EXTRA PRODUCT -->
        <!-- ADD PRODUCT -->
        <v-dialog v-model="dialogAddExtraProduct" overlay-color="white" overlay-opacity="0.5" persistent
                  max-width="640">
            <v-card>
                <v-card-title>
                    Adicionar artigo
                </v-card-title>
                <v-card-text>
                    Tem a certeza que quer adicionar o artigo <span class="red--text">[{{ productExtraToAdd }}]</span>
                    como artigo extra ?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="dialogAddExtraProduct = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addExtraProduct">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- REMOVE PRODUCT -->
        <v-dialog v-model="dialogRemoveExtraProduct" overlay-color="white" overlay-opacity="0.5" persistent
                  max-width="640">
            <v-card>
                <v-card-title>
                    Remover artigo extra
                </v-card-title>
                <v-card-text>
                    Tem a certeza que quer remover o artigo <span class="red--text">[{{ productExtraToRemove }}]</span>
                    como artigo extra ?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="dialogRemoveExtraProduct = false;productExtraToRemove='';">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="removeExtraProduct()">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: {
        productId: String,
    },
    data: function () {
        return {
            product: {},
            productExtraToAdd: '',
            productExtraToRemove: '',
            productExtras: [],
            productsList: [],
            dialogAddExtraProduct: false,
            dialogRemoveExtraProduct: false
        }
    },
    mounted() {
        this.getProductExtras()
        this.getAllProducts()
    },
    methods: {
        getProductExtras() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/products/' + this.productId + '/productExtras'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.productExtras = responseData.data

                            loader.hide();
                        }
                    })

        },
        getAllProducts() {
            ApiWS.dispatch('makeGet', {url: '/api/staff/products/all-prices'})
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.productsList = responseData.products;
                            this.loading = false;
                        }
                    })
        },
        addExtraProduct() {
            let loader = this.$loading.show();

            //Valida se artigoExtra já está associado
            for (const product of this.productExtras) {
                if (product.extraProduct === this.productExtraToAdd) {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Artigo já está associado!',
                        type: 'error'
                    })

                    this.dialogAddExtraProduct = false;

                    return;
                }
            }

            //Envia pedido para acrescentar produto
            let request = {
                url: '/api/staff/products/productExtras',
                data: {
                    product: this.productId,
                    extraProduct: this.productExtraToAdd,
                    quantity: 1
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogAddExtraProduct = false;
                            this.productExtraToAdd = ''

                            loader.hide();

                            this.getProductExtras();
                        }
                    })


        },
        removeExtraProduct() {
            let loader = this.$loading.show();

            //Envia pedido para remover produto
            let request = {
                url: '/api/staff/products/' + this.productId + '/removeProductExtras',
                data: {
                    product: this.productId,
                    extraProduct: this.productExtraToRemove
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogRemoveExtraProduct = false;
                            this.productExtraToRemove = '';

                            loader.hide();

                            this.getProductExtras();
                        }
                    })

        }
    }
}
</script>